import classNames from "classnames/bind";
import React from "react";

import { useIsArcade } from "@/hooks/useIsArcade";

import Splitter, { Props as SplitterProps } from "../splitter";
import styles from "../splitter.module.scss";

export interface Section {
    headline: string;
    body: string;
    infographic: string;
}

export type Props = Omit<SplitterProps, "children"> & {
    sections: Section[];
};

const cx = classNames.bind(styles);

const SplitterInfographics: React.FC<Props> = (props: Props) => {
    const { sections, ...rest } = props;
    const isArcade = useIsArcade();

    const numberedSectionClassName = cx({
        [styles.numberedSection2Cols]: sections.length === 2,
        [styles.numberedSection3Cols]: sections.length === 3,
        [styles.numberedSection4Cols]: sections.length === 4,
    });

    const bodyClassName = cx({
        [styles.body]: true,
        [styles.arcade]: isArcade,
    });

    return (
        <Splitter {...rest}>
            {sections.map((section, index) => {
                const { body, headline, infographic } = section;

                return (
                    <div className={numberedSectionClassName} key={`section-${index}`}>
                        <img
                            className={styles.infographic}
                            src={infographic}
                            alt={`section ${index + 1} infographic`}
                        />
                        {isArcade ? (
                            <h5 className={styles.headline}>{headline}</h5>
                        ) : (
                            <h3 className={styles.headline}>{headline}</h3>
                        )}
                        <p className={bodyClassName}>{body}</p>
                    </div>
                );
            })}
        </Splitter>
    );
};

export default SplitterInfographics;
