import { BuilderConfig } from "../../../builder/types";
import { KINDS, SIZES } from "../../buttons/buttons.constants";
import SuperWideOverlay, { Position, Props } from "./super-wide-overlay";

export const SuperWideOverlayBuilderConfig = {
    name: "SuperWideOverlay",
    inputs: [
        {
            name: "backgroundImage",
            type: "file",
            allowedFileTypes: ["jpg", "jpeg", "png"],
            required: true,
        },
        {
            name: "heading",
            type: "richText",
            required: true,
            defaultValue: "A nice heading",
        },
        {
            name: "body",
            type: "string",
            required: true,
            defaultValue: "Body content goes here",
        },
        {
            name: "containerPosition",
            type: "string",
            required: false,
            enum: Object.values(Position),
            defaultValue: Position.LEFT,
        },
        {
            name: "ctas",
            type: "list",
            required: false,
            onChange: (options: Map<string, Props["ctas"]>): void => {
                if (options.get("ctas").length > 2) {
                    options.set("ctas", options.get("ctas").slice(0, 2));
                    alert("You've reached the maximum number of ctas.");
                }
            },
            defaultValue: [
                {
                    label: "Button Label",
                    url: "/",
                    kind: KINDS.PRIMARY,
                    size: SIZES.LARGE,
                    show: true,
                },
            ],
            subFields: [
                {
                    name: "label",
                    type: "string",
                },
                {
                    name: "url",
                    type: "string",
                },
                {
                    name: "kind",
                    type: "string",
                    enum: Object.values(KINDS),
                    defaultValue: KINDS.PRIMARY,
                },
                {
                    name: "size",
                    type: "string",
                    enum: Object.values(SIZES),
                    defaultValue: SIZES.LARGE,
                },
                {
                    name: "forwardUtm",
                    type: "boolean",
                    defaultValue: false,
                    required: false,
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: SuperWideOverlay,
    config: SuperWideOverlayBuilderConfig,
};

export default builderConfig;
