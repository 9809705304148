import { Theme, useTheme } from "@afterpaytouch/core";
import classNames from "classnames";
import React, { ReactNode } from "react";

import { KIND, SIZE } from "./buttons.constants";
import styles from "./buttons.module.scss";

export interface Props {
    disabled: boolean;
    fluid: boolean;
    onClick: (event) => void;
    kind: KIND;
    label: ReactNode;
    labelElement?: ReactNode;
    chip?: boolean;
    size: SIZE;
    type?: "button" | "submit" | "reset";
}

const ActionButton: React.FC<Props> = ({
    disabled,
    fluid,
    onClick: onClick,
    kind,
    label,
    labelElement,
    chip,
    size,
    type = "button",
}) => {
    const theme = useTheme();
    const currentTheme = theme.theme.selected;
    const buttonStyles = classNames({
        [styles.disabled]: disabled,
        [styles.fluid]: fluid,
        [styles[kind]]: true,
        [styles[size]]: true,
        [styles.chip]: chip,
        [styles.offerLabel]: chip,
        [styles.arcade]: currentTheme === Theme.Cash,
    });
    return (
        <button
            role="button"
            type={type}
            className={buttonStyles}
            onClick={disabled ? null : onClick}
        >
            {labelElement}
            {labelElement && label && <span className={styles.labelElementSpacer} />}
            {label}
        </button>
    );
};

export default ActionButton;
