import { Lethargy } from "lethargy";
import { Interval, DateTime } from "luxon";
import Carousel, { CarouselProps, CarouselSlideRenderControlProps } from "nuka-carousel";
import React, { useState, useEffect, useRef, ReactElement } from "react";
import { CSSTransition } from "react-transition-group";

import { useIsArcade } from "@/hooks/useIsArcade";
import useViewport from "@/hooks/useViewport";

import { TileProps } from "@/types/tile";

import { ArcadeCarouselArrow } from "../icons/arcade-carousel-arrow";
import { CarouselArrow } from "../icons/carousel-arrow";
import {
    CarouselDesktopProps as Carouselv2DesktopProps,
    CarouselMobileProps as Carouselv2MobileProps,
    CarouselTabletProps as Carouselv2TabletProps,
} from "./carousel-v2.constants";
import {
    CarouselDesktopProps,
    CarouselMobileProps,
    CarouselTabletProps,
} from "./carousel.constants";
import styles from "./carousel.module.scss";
import {
    CarouselDesktopProps as CollectionCarouselDesktopProps,
    CarouselMobileProps as CollectionCarouselMobileProps,
    CarouselTabletProps as CollectionCarouselTabletProps,
} from "./collection-carousel.constants";

const lethargy = new Lethargy();

function breakpointCarouselProps(isMobile: boolean, isTablet: boolean, carouselType: string) {
    if (carouselType === "v2") {
        if (isMobile) {
            return Carouselv2MobileProps;
        } else if (isTablet) {
            return Carouselv2TabletProps;
        } else {
            return Carouselv2DesktopProps;
        }
    }
    if (carouselType === "collection") {
        if (isMobile) {
            return CollectionCarouselMobileProps;
        } else if (isTablet) {
            return CollectionCarouselTabletProps;
        } else {
            return CollectionCarouselDesktopProps;
        }
    }
    if (isMobile) {
        return CarouselMobileProps;
    } else if (isTablet) {
        return CarouselTabletProps;
    } else {
        return CarouselDesktopProps;
    }
}

const RightArrow: React.FC<{ props: CarouselSlideRenderControlProps; arrowStyle }> = ({
    props: { goToSlide, currentSlide, slideCount, slidesToShow },
    arrowStyle,
}) => {
    const { isDesktop } = useViewport();
    const [showRightArrow, setShowRightArrow] = useState(false);
    const isArcade = useIsArcade();

    useEffect(() => {
        setShowRightArrow(currentSlide + slidesToShow < slideCount && isDesktop);
    }, [currentSlide, slidesToShow, slideCount, isDesktop]);

    return (
        <CSSTransition
            classNames={{
                enter: styles.arrowEnter,
                enterActive: styles.rightArrowEnterActive,
                enterDone: styles.rightArrowEnterDone,
                exit: styles.rightArrowExit,
                exitActive: styles.rightArrowExitActive,
                exitDone: styles.rightArrowExitDone,
            }}
            in={showRightArrow}
            timeout={200}
        >
            <button
                role="button"
                className={arrowStyle}
                data-testid="carousel-rightArrow"
                onClick={() => goToSlide(currentSlide + slidesToShow)}
            >
                {isArcade ? (
                    <ArcadeCarouselArrow direction="right" />
                ) : (
                    <CarouselArrow direction="right" />
                )}
            </button>
        </CSSTransition>
    );
};

const LeftArrow: React.FC<{ props: CarouselSlideRenderControlProps; arrowStyle }> = ({
    props: { goToSlide, currentSlide, slidesToShow },
    arrowStyle,
}) => {
    const { isDesktop } = useViewport();
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const isArcade = useIsArcade();

    useEffect(() => {
        setShowLeftArrow(currentSlide > 0 && isDesktop);
    }, [currentSlide, isDesktop]);

    return (
        <CSSTransition
            classNames={{
                enter: styles.arrowEnter,
                enterActive: styles.leftArrowEnterActive,
                enterDone: styles.leftArrowEnterDone,
                exit: styles.leftArrowExit,
                exitActive: styles.leftArrowExitActive,
                exitDone: styles.leftArrowExitDone,
            }}
            in={showLeftArrow}
            timeout={200}
        >
            <button
                role="button"
                className={arrowStyle}
                onClick={() => goToSlide(currentSlide - slidesToShow)}
            >
                {isArcade ? (
                    <ArcadeCarouselArrow direction="left" />
                ) : (
                    <CarouselArrow direction="left" />
                )}
            </button>
        </CSSTransition>
    );
};

const handleWheelEvent = (event, currentSlide, goToSlide, lastSlideTime, setLastSlideTime) => {
    const interval = Interval.fromDateTimes(lastSlideTime, DateTime.local());
    if (event.deltaX) {
        event.preventDefault();
        event.stopPropagation();
    }

    // Trackpad scrolling sends 5-6 near-simultaneous deltaX events, we only keep the first to occur every .3s
    if (event.deltaX && Math.abs(event.deltaX) >= 0 && interval.length() > 300) {
        const modifier = event.deltaX > 0 ? 1 : -1;
        setLastSlideTime(DateTime.local());
        setTimeout(() => {
            goToSlide(currentSlide + modifier * 6);
        }, 50); // Compensate for useEffect's slight delay
    }
};

type Props = CarouselProps & { carouselType: string; children: Array<ReactElement<TileProps>> };
const CardCarousel: React.FC<Props> = ({ carouselType, children, ...rest }) => {
    const { isMobile, isTablet } = useViewport();

    const [lastSlideTime, setLastSlideTime] = useState(DateTime.local());
    const [carouselProps, setCarouselProps] = useState(
        breakpointCarouselProps(isMobile, isTablet, carouselType)
    );
    const carouselWrapperRef = useRef(null);
    const carouselRef = useRef(null);

    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [lazyChildren, setLazyChildren] = useState<Array<ReactElement<TileProps>>>([]);
    const [carouselChildren, setCarouselChildren] = useState<Array<ReactElement<TileProps>>>([]);
    const rightArrowStyle =
        carouselType === "collection" ? styles.rightArrowCol : styles.rightArrow;
    const leftArrowStyle = carouselType === "collection" ? styles.leftArrowCol : styles.leftArrow;
    useEffect(() => {
        if (!Array.isArray(children)) {
            return;
        }

        const twoPagesOfChildren = carouselProps.slidesToShow * 2;
        if (currentIndex < lazyChildren.length - twoPagesOfChildren) {
            // Paging backwards doesn't need to slice the array again, it would unload elements.
            return;
        }

        setLazyChildren(children.slice(0, currentIndex + twoPagesOfChildren));
    }, [currentIndex, carouselProps.slidesToShow, children, lazyChildren.length]);

    useEffect(() => {
        if (!Array.isArray(children)) {
            setCarouselChildren(children);
            return;
        }

        setCarouselChildren(
            lazyChildren.map((child: ReactElement<TileProps>, i: number) => {
                const tabIndex =
                    i >= currentIndex && i < currentIndex + carouselProps.slidesToShow ? 0 : -1;

                return <child.type {...child.props} tabIndex={tabIndex} key={tabIndex} />;
            })
        );
    }, [lazyChildren, children, currentIndex, carouselProps.slidesToShow]);

    useEffect(() => {
        setCarouselProps(breakpointCarouselProps(isMobile, isTablet, carouselType));
    }, [isMobile, isTablet, carouselType]);

    useEffect(() => {
        const wheelHandler = (event) => {
            if (lethargy.check(event)) {
                handleWheelEvent(
                    event,
                    carouselRef.current.state.currentSlide,
                    carouselRef.current.goToSlide,
                    lastSlideTime,
                    setLastSlideTime
                );
            }
        };

        const carouselWrapperCurrent = carouselWrapperRef.current;
        if (!carouselRef.current || !carouselWrapperCurrent) {
            return;
        }

        carouselWrapperCurrent.addEventListener("wheel", wheelHandler, {
            passive: false,
            capture: true,
        });

        return () =>
            carouselWrapperCurrent &&
            carouselWrapperCurrent.removeEventListener("wheel", wheelHandler, {
                passive: false,
                capture: true,
            });
    }, [lastSlideTime]);

    return (
        <Carousel
            ref={carouselRef}
            innerRef={carouselWrapperRef}
            className={styles.tileContainer}
            width={"auto"}
            slidesToScroll={"auto"}
            slideIndex={currentIndex}
            afterSlide={setCurrentIndex}
            slideWidth={carouselProps.slideWidth}
            cellSpacing={carouselProps.cellSpacing}
            slidesToShow={carouselProps.slidesToShow}
            frameOverflow={carouselProps.frameOverflow}
            renderBottomCenterControls={null}
            renderCenterLeftControls={(props) => (
                <LeftArrow props={props} arrowStyle={leftArrowStyle} />
            )}
            renderCenterRightControls={(props) => (
                <RightArrow props={props} arrowStyle={rightArrowStyle} />
            )}
            {...rest}
        >
            {carouselChildren}
        </Carousel>
    );
};

const CardCarouselGuard: React.FC<Props> = ({ carouselType, children, ...rest }) => {
    const { hasWindow } = useViewport();

    if (!hasWindow) {
        return null;
    }

    return (
        <CardCarousel carouselType={carouselType} {...rest}>
            {children}
        </CardCarousel>
    );
};

export default CardCarouselGuard;
