import classname from "classnames/bind";
import { isEmpty } from "lodash";
import React from "react";

import { getHostname, getSearchParams } from "@/utils/urlHelper";

import type { UI_CONTEXT } from "../../../analytics/constants";
import EVENTS from "../../../analytics/events";
import LinkButton, { Props as LinkButtonProps } from "../../buttons/link-button";
import styles from "./youthHeadline.module.scss";

const cx = classname.bind(styles);

export enum Position {
    LEFT = "left",
    RIGHT = "right",
}

export type Props = {
    backgroundImage: string;
    heading: string;
    headline: string;
    body: string;
    ctas: LinkButtonProps[];
    containerPosition?: Position;
    uiContext: UI_CONTEXT;
};

const YouthHeadline: React.FC<Props> = ({
    heading,
    headline,
    body,
    backgroundImage,
    ctas,
    uiContext,
    containerPosition = Position.LEFT,
}) => {
    let image = backgroundImage;
    const imageHostname = getHostname(image);
    if (imageHostname === "cdn.builder.io") {
        const imageParams = getSearchParams(image);
        image = isEmpty(imageParams) ? `${image}?format=webp` : `${image}&format=webp`;
    }

    const overlayClassName = cx({
        [styles.overlay]: true,
        [styles.overlayLeft]: containerPosition === Position.LEFT,
        [styles.overlayRight]: containerPosition === Position.RIGHT,
    });

    return (
        <div className={styles.container}>
            <div className={styles.backgroundContainer}>
                <div className={styles.background} style={{ backgroundImage: `url("${image}")` }} />
            </div>
            <div className={styles.headlineContent}>{headline}</div>
            <div className={overlayClassName}>
                <div
                    className={styles.overlayHeading}
                    dangerouslySetInnerHTML={{ __html: heading }}
                ></div>
                {body && <p className={styles.overlayBody}>{body}</p>}
                {ctas && (
                    <div className={styles.ctaContainer}>
                        {ctas.map((cta, index) => (
                            <div
                                data-testid="youth-headline-button"
                                className={styles.ctaOne}
                                key={`cta-container-${index}`}
                            >
                                <LinkButton
                                    disabled={false}
                                    fluid={true}
                                    kind={cta.kind}
                                    label={cta.label}
                                    size={cta.size}
                                    show={true}
                                    url={cta.url}
                                    newTab={false}
                                    forwardUtm={cta.forwardUtm}
                                    analytics={{
                                        eventName: EVENTS.BANNER_CLICK,
                                        uiContext: uiContext,
                                        eventProps: {
                                            title: heading,
                                            description: body,
                                            outboundLink: cta.url,
                                        },
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default YouthHeadline;
