import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import React, { KeyboardEventHandler } from "react";

import { HEADING_LENGTHS } from "@/shared/constants";
import { visitStoreWebsite } from "@/shared/helpers/tracking-helper";

import { truncateWithEllipsis } from "@/utils/strings";

import { ANALYTICS } from "@/analytics/constants";
import EVENTS from "@/analytics/events";
import { trackUserEvent } from "@/analytics/tracking";

import { useLanguageState } from "@/context/LanguageContext";

import { KINDS, SIZES } from "@/components/buttons/buttons.constants";
import LinkButton from "@/components/buttons/link-button";
import OfferButton from "@/components/store-tiles/offer-button";

import { useFeatureFlags } from "@/hooks/useFeatureFlags";

import { CategoryProps } from "@/interfaces/category-interface";
import { StoreProps } from "@/interfaces/stores-interface";

import { TileProps } from "@/types/tile";

import styles from "./storeTiles.module.scss";

export type StoreTileProps = {
    store: StoreProps;
    category?: CategoryProps;
    parentCategory?: CategoryProps;
    offerButtonClickHandler: (store: StoreProps) => void;
    analytics: ANALYTICS;
    featured?: boolean;
} & TileProps;

export const StoreTile: React.FC<StoreTileProps> = (props) => {
    const {
        store,
        position,
        category,
        analytics,
        offerButtonClickHandler,
        featured,
        tabIndex = 0,
    } = props;
    const { inStoreExclusiveShopTile } = useFeatureFlags();
    const router = useRouter();
    const { localization } = useLanguageState();
    const { t } = useTranslation();
    const {
        merchantId,
        hasLocations,
        inStoreOnly,
        name,
        mainImageUrl,
        featuredImageUrl,
        newLogoUrl,
        offerTeaser,
        offerInStore,
        offerOnline,
        offerActive,
        shopUrl,
        locationUrl,
    } = store;

    const eventProps = {
        shopUrl,
        merchantName: name,
        merchantId: merchantId,
        categoryId: analytics.eventProps?.categoryId,
        httpCorrelationId: analytics.eventProps?.httpCorrelationId,
        hasLocations,
        hasOffer: offerActive,
        position: position,
        categoryName: category?.name,
        query: analytics?.searchTerm,
        offerButton: false,
    };
    const storeTileImageStyles = {
        backgroundImage: `url("${featured ? featuredImageUrl : mainImageUrl}")`,
    };
    const onClick = () => {
        trackUserEvent(
            localization.locale,
            router.asPath,
            analytics.uiContext,
            EVENTS.SHOP_DIRECTORY_CLICK,
            eventProps
        );

        if (inStoreOnly) {
            return visitStoreWebsite(locationUrl, true);
        }
        return visitStoreWebsite(shopUrl);
    };

    const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
        if (e.key === "Enter" || e.key === "Space") {
            onClick();
        }
    };

    const storeLabel = inStoreExclusiveShopTile
        ? inStoreOnly
            ? t("store.inStoreExclusive")
            : t("store.onlineAndInStore")
        : t("store.instore");

    return (
        <div
            data-testid="store-tile"
            className={featured ? styles.featuredStoreTileContainer : styles.storeTileContainer}
            role={"button"}
            onClick={onClick}
            tabIndex={tabIndex}
            onKeyDown={handleKeyDown}
        >
            <div className={styles.storeTileTopImageContainer} style={storeTileImageStyles}>
                <div className={styles.storeTileOffersContainer}>
                    {hasLocations && locationUrl && (
                        <LinkButton
                            kind={KINDS.CARD_BLACK}
                            size={SIZES.TINY}
                            fluid={false}
                            show
                            disabled={false}
                            label={storeLabel}
                            url={locationUrl}
                            newTab={true}
                            prefetch={false}
                            analytics={{
                                eventName: EVENTS.SHOP_DIRECTORY_CLICK,
                                eventProps: eventProps,
                                uiContext: analytics.uiContext,
                            }}
                            testId="in-store-tile"
                        />
                    )}

                    {offerTeaser && (
                        <OfferButton
                            label={offerTeaser}
                            inStore={offerInStore}
                            online={offerOnline}
                            onClick={(e) => {
                                e.stopPropagation();
                                offerButtonClickHandler(store);
                            }}
                        />
                    )}
                </div>
            </div>

            {/* avatar zone */}
            {!featured && (
                <div className={styles.storeTileBottomContainer}>
                    <img
                        className={styles.storeTileLogo}
                        src={newLogoUrl}
                        alt={t("store.storeLogo")}
                    />
                    <p className={styles.storeTileName}>
                        {truncateWithEllipsis(name, HEADING_LENGTHS.TINY)}
                    </p>
                </div>
            )}
        </div>
    );
};
