import { transformStoreLocationUrl } from "@/utils/strings";

export interface StoresProps {
    stores: Array<StoreProps>;
}

export interface ThumbStoreProps {
    id: string;
    name: string;
    mainImageUrl: string;
    merchantId: number;
    slug: string;
    newLogoUrl: string;
}

export interface StoreProps {
    id: string;
    name: string;
    mainImageUrl: string;
    unprocessedMainImageUrl: string | null;
    merchantId: number;
    slug: string;
    locale: string;
    shopUrl: string;
    merchantOutboundUrl: string;
    hasLocations: boolean;
    inStoreOnly: boolean;
    offerText: string | null;
    offerUrl: string | null;
    offerTerms: string | null;
    offerCtaTitle: string | null;
    offerTeaser: string | null;
    offerInStore: boolean;
    offerOnline: boolean;
    offerActive: boolean;
    textBlurb: string | null;
    description: string | null;
    newLogoUrl: string;
    featuredImageUrl: string;
    bannerImageUrl: string;
    comingSoon: boolean;
    merchantPage: boolean;
    locationUrl: string;
    landingPageUrl: string;
    highlight?: string;
    offer_affiliate_network?: string;
}

export interface APISuggestionProps {
    id: string;
    type: string;
    attributes: {
        query: string;
        query_suggestion: string;
    };
}

export interface SuggestionProps {
    id: string;
    name: string;
    type: string;
    query: string;
    query_suggestion: string;
}

export interface StoresWithCountProps {
    totalCount: number;
    data: Array<StoreProps>;
}

export interface APIStoreProps {
    id: string;
    attributes: {
        name: string;
        main_image_url: string;
        merchant_id: number;
        permalink: string;
        slug: string;
        locale: string;
        shop_url: string;
        merchant_outbound_url: string;
        has_locations: boolean;
        offer_text: string;
        offer_url: string;
        offer_terms: string;
        offer_cta_title: string;
        offer_in_store: boolean;
        offer_online: boolean;
        offer_teaser: string;
        offer_starts_at: string;
        offer_ends_at: string;
        offer_active: boolean;
        in_store_only: boolean;
        text_blurb: string;
        description: string;
        logo_url: string;
        new_logo_url: string;
        featured_image_url: string;
        banner_image_url: string;
        coming_soon: boolean;
        merchant_page: boolean;
        highlight?: string;
        offer_affiliate_network?: string;
        avatar_url?: string;
    };
    relationships: {
        locations: {
            meta: {
                included: boolean;
            };
        };
    };
    links: {
        location_url: string;
        landing_page_url: string;
    };
}

export interface APIStoreLandingPageProps {
    data: {
        id: string;
        attributes: {
            name: string;
            main_image_url: string;
            merchant_id: number;
            permalink: string;
            slug: string;
            locale: string;
            shop_url: string;
            merchant_outbound_url: string;
            has_locations: boolean;
            offer_text: string;
            offer_url: string;
            offer_terms: string;
            offer_cta_title: string;
            offer_in_store: boolean;
            offer_online: boolean;
            offer_teaser: string;
            offer_starts_at: string;
            offer_ends_at: string;
            offer_active: boolean;
            in_store_only: boolean;
            text_blurb: string;
            description: string;
            new_logo_url: string;
            featured_image_url: string;
            banner_image_url: string;
            coming_soon: boolean;
            merchant_page: boolean;
            highlight?: string;
            offer_affiliate_network?: string;
            avatar_url?: string;
        };
        relationships: {
            locations: {
                meta: {
                    included: boolean;
                };
            };
        };
        links: {
            location_url: string;
            landing_page_url: string;
        };
    };
    meta: { unprocessed_main_image_url: string };
}

export interface APIStoresWithCountProps {
    data: Array<APIStoreProps>;
    meta: {
        total: number;
    };
    links: {
        self: string | null;
        first: string | null;
        next: string | null;
        prev: string | null;
        last: string | null;
    };
    jsonapi: {
        version: string;
    };
}

export const transformStore = (store: APIStoreProps): StoreProps => {
    // TODO - This is unfortunately required until a better solution presents itself
    // https://github.com/vercel/next.js/discussions/11209#discussioncomment-118119

    const avatarUrl = store.attributes.avatar_url;
    const logoUrl = store.attributes.new_logo_url;

    return JSON.parse(
        JSON.stringify({
            id: store.id,
            slug: store.attributes.slug,
            locale: store.attributes.locale,
            name: store.attributes.name,
            mainImageUrl: store.attributes.main_image_url,
            merchantId: store.attributes.merchant_id,
            shopUrl: store.attributes.shop_url,
            merchantOutboundUrl: store.attributes.merchant_outbound_url,
            hasLocations: store.attributes.has_locations,
            offerText: store.attributes.offer_text,
            offerUrl: store.attributes.offer_url,
            offerTerms: store.attributes.offer_terms,
            offerCtaTitle: store.attributes.offer_cta_title,
            offerInStore: store.attributes.offer_in_store,
            offerOnline: store.attributes.offer_online,
            offerTeaser: store.attributes.offer_teaser,
            inStoreOnly: store.attributes.in_store_only,
            offerActive: store.attributes.offer_active,
            textBlurb: store.attributes.text_blurb,
            description: store.attributes.description,
            newLogoUrl: avatarUrl ?? logoUrl,
            featuredImageUrl: store.attributes.featured_image_url,
            bannerImageUrl: store.attributes.banner_image_url,
            comingSoon: store.attributes.coming_soon,
            merchantPage: store.attributes.merchant_page,
            locationUrl: `/${transformStoreLocationUrl(store.links.location_url)}`,
            landingPageUrl: store.links.landing_page_url,
            highlight: store.attributes.highlight,
            offer_affiliate_network: store.attributes.offer_affiliate_network,
        })
    );
};

export const transformStoreLandingPage = (store: APIStoreLandingPageProps): StoreProps => {
    // TODO - This is unfortunately required until a better solution presents itself
    // https://github.com/vercel/next.js/discussions/11209#discussioncomment-118119

    return JSON.parse(
        JSON.stringify({
            id: store.data.id,
            slug: store.data.attributes.slug,
            locale: store.data.attributes.locale,
            name: store.data.attributes.name,
            mainImageUrl: store.data.attributes.main_image_url,
            unprocessedMainImageUrl: store.meta.unprocessed_main_image_url,
            merchantId: store.data.attributes.merchant_id,
            shopUrl: store.data.attributes.shop_url,
            merchantOutboundUrl: store.data.attributes.merchant_outbound_url,
            hasLocations: store.data.attributes.has_locations,
            offerText: store.data.attributes.offer_text,
            offerUrl: store.data.attributes.offer_url,
            offerTerms: store.data.attributes.offer_terms,
            offerCtaTitle: store.data.attributes.offer_cta_title,
            offerInStore: store.data.attributes.offer_in_store,
            offerOnline: store.data.attributes.offer_online,
            offerTeaser: store.data.attributes.offer_teaser,
            inStoreOnly: store.data.attributes.in_store_only,
            offerActive: store.data.attributes.offer_active,
            textBlurb: store.data.attributes.text_blurb,
            description: store.data.attributes.description,
            newLogoUrl: store.data.attributes.new_logo_url,
            featuredImageUrl: store.data.attributes.featured_image_url,
            bannerImageUrl: store.data.attributes.banner_image_url,
            comingSoon: store.data.attributes.coming_soon,
            merchantPage: store.data.attributes.merchant_page,
            locationUrl: `/${transformStoreLocationUrl(store.data.links.location_url)}`,
            landingPageUrl: store.data.links.landing_page_url,
            highlight: store.data.attributes.highlight,
            offer_affiliate_network: store.data.attributes.offer_affiliate_network,
        })
    );
};

export const transformStores = (stores: Array<APIStoreProps>): Array<StoreProps> => {
    if (!stores) {
        return [];
    }
    return stores.map(transformStore);
};

export const transformSuggestion = (suggestion: APISuggestionProps): SuggestionProps => {
    return {
        id: suggestion.id,
        name: suggestion.attributes.query_suggestion,
        type: suggestion.type,
        query: suggestion.attributes.query,
        query_suggestion: suggestion.attributes.query_suggestion,
    };
};

export const transformSuggestions = (
    suggestions: Array<APISuggestionProps>
): Array<SuggestionProps> => {
    if (!suggestions || suggestions.length == 0) {
        return [];
    }
    return suggestions.map(transformSuggestion);
};

export const transformStoresWithCount = (stores: APIStoresWithCountProps): StoresWithCountProps => {
    if (!stores) {
        return { totalCount: 0, data: [] };
    }
    return {
        totalCount: stores.meta.total,
        data: transformStores(stores.data),
    };
};
