import React, { useMemo } from "react";

type Props = {
    direction: "left" | "right";
};

export const ArcadeCarouselArrow: React.FC<Props> = ({ direction }) => {
    const icon = useMemo(() => {
        switch (direction) {
            case "left":
                return <LeftArrowSVG />;
            case "right":
                return <RightArrowSVG />;
        }
    }, [direction]);

    return (
        <div
            style={{
                borderRadius: "0.5rem",
                overflow: "hidden",
            }}
        >
            {icon}
        </div>
    );
};

const RightArrowSVG: React.FC = () => {
    return (
        <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 12C0 5.37258 5.37258 0 12 0H52C58.6274 0 64 5.37258 64 12V52C64 58.6274 58.6274 64 52 64H12C5.37258 64 0 58.6274 0 52V12Z"
                fill="#00D533"
            />
            <path
                d="M42.707 31.2929L35 23.5859L33.586 24.9999L39.586 30.9999L22 30.9999C21.447 30.9999 21 31.4479 21 31.9999C21 32.5519 21.447 32.9999 22 32.9999H39.586L33.586 38.9999L35 40.4139L42.707 32.7069C43.098 32.3159 43.098 31.6839 42.707 31.2929Z"
                fill="white"
            />
        </svg>
    );
};

const LeftArrowSVG: React.FC = () => {
    return (
        <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M64 12C64 5.37258 58.6274 0 52 0H12C5.37258 0 0 5.37258 0 12V52C0 58.6274 5.37258 64 12 64H52C58.6274 64 64 58.6274 64 52V12Z"
                fill="#00D533"
            />
            <path
                d="M21.293 31.2929L29 23.5859L30.414 24.9999L24.414 30.9999L42 30.9999C42.553 30.9999 43 31.4479 43 31.9999C43 32.5519 42.553 32.9999 42 32.9999H24.414L30.414 38.9999L29 40.4139L21.293 32.7069C20.902 32.3159 20.902 31.6839 21.293 31.2929Z"
                fill="white"
            />
        </svg>
    );
};
