import { Theme, useTheme } from "@afterpaytouch/core";
import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import qs, { ParsedUrlQuery } from "querystring";
import React, { ReactNode, useEffect, useState } from "react";

import { ANALYTICS, UI_CONTEXT } from "@/analytics/constants";
import EVENTS from "@/analytics/events";
import { trackUserEvent } from "@/analytics/tracking";

import { useLanguageState } from "@/context/LanguageContext";

import { useIsArcade } from "@/hooks/useIsArcade";

import { KIND, SIZE } from "./buttons.constants";
import styles from "./buttons.module.scss";

export interface Props {
    kind: KIND;
    size: SIZE;
    url?: string;
    label?: string;
    bold?: boolean;
    labelElement?: ReactNode;
    disabled?: boolean;
    fluid?: boolean;
    newTab?: boolean;
    analytics?: ANALYTICS;
    overrideFocusBorder?: boolean;
    show?: boolean;
    uiContext?: UI_CONTEXT;
    passHref?: boolean;
    forwardUtm?: boolean;
    prefetch?: boolean;
    testId?: string;
    onClick?: (...args: unknown[]) => void;
}

const LinkButton: React.FC<Props> = ({
    kind,
    label,
    bold = false,
    labelElement,
    size,
    url,
    disabled = false,
    fluid = false,
    newTab = false,
    show = true,
    overrideFocusBorder = true,
    uiContext = null,
    forwardUtm = false,
    prefetch = false,
    analytics,
    testId,
    onClick,
}) => {
    const { localization } = useLanguageState();
    const router = useRouter();
    const theme = useTheme();
    const isArcade = useIsArcade();
    const currentTheme = theme.theme.selected;
    const [pathname, setPathname] = useState<string>("");
    const [urlQueryParams, setUrlQueryParams] = useState<ParsedUrlQuery>({});
    const [utmQueryParams, setUtmQueryParams] = useState<ParsedUrlQuery>({});
    const [hash, setHash] = useState<string>("");
    const [query, setQuery] = useState<ParsedUrlQuery>({
        ...utmQueryParams,
        ...urlQueryParams,
    });

    const splitURL = (url) => {
        const [hashless, hashed] = url.split("#");
        const [urlPath, urlQuery] = hashless.split("?");
        setUrlQueryParams(qs.parse(urlQuery));
        setPathname(urlPath);
        setHash(hashed);
    };

    useEffect(() => {
        if (!forwardUtm) {
            return;
        }

        const utmKeyPattern = new RegExp("^utm", "i");

        const matchingQueryParams = Object.entries(router.query).reduce((accum, [key, value]) => {
            if (key.match(utmKeyPattern)) {
                return { ...accum, [key]: value };
            }
            return accum;
        }, {});

        setUtmQueryParams(matchingQueryParams);
        splitURL(url);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setQuery({ ...utmQueryParams, ...urlQueryParams });
    }, [urlQueryParams, utmQueryParams]);

    useEffect(() => {
        if (!url) {
            return;
        }
        splitURL(url);
    }, [url]);

    const handleClick = async () => {
        const path = router.asPath;

        if (analytics) {
            const { eventName, eventProps, uiContext } = analytics;

            trackUserEvent(localization.locale, path, uiContext, eventName, {
                title: eventProps?.title || label,
                outboundLink: url,
                ...eventProps,
            });
        } else {
            // Default click event
            trackUserEvent(localization.locale, path, uiContext, EVENTS.BUTTON_CLICK, {
                title: label,
                outboundLink: url,
            });
        }
        onClick?.();
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    };

    const buttonStyles = classNames({
        [styles.disabled]: disabled,
        [styles.fluid]: fluid,
        [styles[kind]]: true,
        [styles[size]]: true,
        [styles.hide]: !show,
        [styles.overrideFocusBorder]: overrideFocusBorder,
        [styles.fontWeightLight]: true,
        [styles.fontWeightBold]: bold,
        [styles.arcade]: currentTheme === Theme.Cash && isArcade,
    });

    const dataAttributes = testId ? { "data-testid": testId } : {};

    return (
        <>
            {url || onClick ? (
                <Link
                    href={{ pathname, query, hash }}
                    passHref
                    prefetch={prefetch}
                    tabIndex={0}
                    role={"button"}
                    className={buttonStyles}
                    target={!newTab || disabled ? null : "_blank"}
                    onClick={(e) => {
                        stopPropagation(e);
                        if (!disabled) handleClick();
                    }}
                    {...dataAttributes}
                >
                    {labelElement}
                    {labelElement && label && <span className={styles.labelElementSpacer} />}
                    {label}
                </Link>
            ) : (
                <span className={buttonStyles}>
                    {labelElement}
                    {labelElement && label && <span className={styles.labelElementSpacer} />}
                    {label}
                </span>
            )}
        </>
    );
};

export default LinkButton;
